<template>
    <div>
        <div class="welcome-loading-box" v-if="loading">
            <Loading aria="Logout - loading" class="loading"></Loading>
            <div v-if="secondsToLogout">
                <div class="">You will be logged out from my.vermont.gov</div>
                <div>in {{ secondsToLogout }} seconds...</div>
                <p class="stop-pill" v-on:click="stopLogout">Click here to stay logged in and see other myVermont sites...
                </p>
            </div>
            <div v-else>Now logging out from myVermont...</div>

        </div>
        <div v-if="!loading"></div>
    </div>
</template>

<script>
// import Vue from "vue";
import Loading from './features/LoadingWidget'

export default {
    name: 'logout-loader',
    components: {
        Loading,
    },
    props: ['envLinks'],
    data() {
        return {
            loading: false,
            secondsToLogout: 10,
            currSeconds: 0,
            mostRecentVisit: '',
            timer: 0,
        }
    },
    beforeCreate() {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const redirect = urlParams.get('redirect')
        console.log(redirect)
    },
    created() {

        this.loading = true
        this.timer = setInterval(this.startTimer, 1000)
    },
    methods: {
        makeRequest() { },
        resetTimer() {
            /* Clear the previous interval */
            clearInterval(this.timer)
            /* Reset the seconds of the timer */
            this.currSeconds = 0
            /* Set a new interval */
            setInterval(this.startTimer, 1000)
        },
        async startTimer() {
            /* Increment the timer seconds */

            this.secondsToLogout--
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)
            const redirect = urlParams.get('redirect')
            console.log(redirect)

            //Handle redirect param - e.g. https://info.my-dev.vermont.gov/logout?redirect=labor.vermont.gov
            if (this.secondsToLogout <= 0) {
                await this.$auth.logout()
                if (redirect != null) {
                    let targetUrl = "https://" + redirect
                    console.log(targetUrl)
                    if (targetUrl !== '') {
                        window.location.href = targetUrl
                    }
                } else {
                    console.log(this.envLinks.infoApp)
                    await this.isAuthenticated()
                    window.location.href = this.envLinks.infoApp
                }
            }
        },
        stopLogout() {
            clearInterval(this.timer)
            this.stopRedirect = true
            this.$router.push({ path: "/welcome" });
        },
    },
}
</script>

<style scoped>
@import './styles/Welcome.css';
</style>
