<template>
    <div class="info-home">
        <div class="infobody-home">
            <div class="white-header">
                <div class="portal-header">
                    <div class="portal-sub">
                        <img class="home-logo" src="../assets/momNoText.png" />
                        <p class="my-vt">myVermont</p>
                        <p class="">
                            Single sign-on for access to <strong>critical</strong>
                            Vermont <strong>applications</strong> and <strong>services</strong>.
                        </p>
                        <router-link class="main-help-link" :to="'help-home'">
                            <div class="help-wrap">
                                <a><font-awesome-icon icon="info-circle" /></a>
                                <div>
                                    <p>
                                        <b class=""> <a> myVermont Help</a></b>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="searchbtn-login-wrap">
                        <div class="quick-btn-wrap">
                            <button aria-label="Show all apps." @click="clearSearch()"
                                class="quick-search-btn quick-search-btn-clear"><a>Show All</a></button>
                            <button aria-label="App search buttons." v-bind:key="term" v-for="term in sortedSearchList"
                                class="quick-search-btn" @click="setQuickSearch(term)">
                                <a>{{ term }}</a>
                            </button>
                            <button aria-label="Department search buttons." v-bind:key="term"
                                v-for="term in deptartmentArray" class="quick-search-btn quick-search-btn-dept"
                                @click="setQuickDepartment(term)">
                                <a>{{ term }}</a>
                            </button>
                        </div>
                        <div class="loading-box" v-if="isLoading">
                            <Loading class="loading"></Loading>
                            <div>Loading</div>
                        </div>
                        <div class='user-options' v-else>
                            <div v-if="isLoggedIn" class="logged-in-notice">
                                <router-link class="logged-in-router-link" to="/welcome">
                                    <button class="logged-in-content-wrap btn btn-success">
                                        <img src="../assets/unlock.svg" />

                                        <p class="logged-in-text">You are logged in. Click here to see your landing
                                            page.</p>

                                    </button>
                                </router-link>
                            </div>
                            <div class="logged-in-notice">
                                <button @click="goToLink(oktaProfile)" class="logged-in-content-wrap btn btn-success">
                                    <EditUser />

                                    <a href>
                                        <p class="logged-in-text">Update your myVermont account (log in required).</p>
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="app-btn-wrap">
                <div class="benefits-home-block external-benefits-link index-para search-bar">
                    <div class="search-wrapper">
                        <font-awesome-icon class="svg search-icon" :icon="'search'" />
                        <input class="search search-box" placeholder="Find a myVermont app..." type="text"
                            v-model="search" />
                        <div>
                            <button aria-label="Result count with link to scroll down to results."
                                class="quick-search-btn quick-search-btn-count">
                                <span class="app-count"><b>{{ countSearchResults }}</b> app<span
                                        v-if="countSearchResults != 1">s</span></span>
                            </button>
                        </div>
                        <a class="clear-icon" @click="clearSearch()"><font-awesome-icon class="svg"
                                :icon="'minus-circle'" /></a>
                    </div>
                </div>
                <div :id="'dept-agency-container-' + index" class="dept-agency-container" v-bind:key="index"
                    v-for="(value, name, index) in sortedSearchResults">
                    <p>{{ name }}</p>
                    <ul class="two-col">
                        <li v-for="app in value" v-bind:key="app.title" class="external-benefits-link">
                            <AppButton class="app-button" :appText="app.title" :appDesc="app.appDesc"
                                :appLink="envLinks[app.envLinks]" :routerLink="app.routerLink"
                                :fontawesomePath="app.fontawesomePath" :svgPath="app.svgPath" :btnCall="app.btnCall"
                                :agencyDept="app.agencyCode" :helpLink="app.helpLink" :idProof="app.idProof"></AppButton>
                        </li>
                    </ul>
                </div>
                <ul>
                    <li v-if="sortedSearchResults.length == 0">No results</li>
                    <li class="footer-what-is">
                        <WhatIsMyVermont></WhatIsMyVermont>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { createApp } from 'vue'

const app = createApp({})

import Loading from './features/LoadingWidget'
import AppButton from './features/AppButton'
import WhatIsMyVermont from './features/WhatIsMy'
import allApps from './allApps.json'
import allDepartments from './allDepartments.json'
import EditUser from './../assets/EditUser.vue'


import { defineComponent, ref } from 'vue'
import { useVueFuse } from 'vue-fuse'
export default defineComponent({
    props: {
        envLinks: {
            type: Object,
        },
    },
    setup(props) {
        const myList = []
        allApps.forEach((app) => {
            console.log(app.title, app.envs)
            console.log(props.envLinks.env)
            console.log(app.envs.includes(props.envLinks.env))

            if (app.envs.includes(props.envLinks.env)) {
                console.log("Added " + app.title)

                myList.push(app)
            }
        })
        const allDept = allDepartments
        const fuseConfig = {
            includeScore: true,
            minMatchCharLength: 3,
            keys: [
                { name: 'searchTerms', weight: 5 },
                { name: 'appDesc', weight: 2 },
                { name: 'title', weight: 2 },
            ],
        }

        const { search, results, noResults, resultsRaw } = useVueFuse(myList, fuseConfig)
        //search - search term
        //results - 
        //noResults - 
        //resultsRaw - 

        console.log("search", search)
        console.log("results", results)
        console.log("noResults", noResults)
        console.log("resultsRaw", resultsRaw)

        // expose to template and other options API hooks
        const deptArray = []
        for (const dept in allDept) {
            if (allDept[dept].envs.includes(props.envLinks.env)) {
                deptArray.push(allDept[dept].code)
            }
        }
        deptArray.sort()
        let loading = ref(true)

        return {
            isLoading: loading,
            activeUser: null,
            isLoggedIn: false,
            filterText: '',
            allApps: myList,
            quickSearchTerms: props.envLinks.searchTerms,
            quickSearchDepartments: [],
            search,
            results,
            resultsRaw,
            resultsUnfiltered: myList,
            noResults,
            deptartmentArray: deptArray,
            activeDepartment: '',
        }
    },
    name: 'home-default',
    data() {
        return {
            oktaProfile: process.env.VUE_APP_OKTA_DOMAIN + "/enduser/settings",
        }
    },
    components: {
        Loading,
        AppButton,
        WhatIsMyVermont,
        EditUser
    },
    async mounted() {
        this.result = await this.getLoggedInStatus()
        this.isLoading = false
        this.$parent.refreshActiveUser()
    },
    computed: {
        sortedSearchResults: function () {
            let filteredResults = []
            let deptartmentAgencyList = {}

            if (this.search == '' && this.activeDepartment == '') {
                filteredResults = this.allApps
            } else if (this.activeDepartment !== '') {
                this.resultsUnfiltered.forEach((res) => {
                    if (res.agencyCode == this.activeDepartment) {
                        filteredResults.push(res)
                    }
                })
            } else {
                this.resultsRaw.forEach((res) => {
                    if (res.score < 0.002) {
                        filteredResults.push(res.item)
                    }
                })
            }
            filteredResults.forEach((app) => {
                if (!(app.agencyDept in deptartmentAgencyList)) {
                    deptartmentAgencyList[app.agencyDept] = []
                    deptartmentAgencyList[app.agencyDept].push(app)
                } else {
                    deptartmentAgencyList[app.agencyDept].push(app)
                }
            })

            return deptartmentAgencyList
        },
        countSearchResults() {
            let cnt = 0
            for (const app in this.sortedSearchResults) {
                cnt += this.sortedSearchResults[app].length
            }
            return cnt
        },
        sortedSearchList: function () {
            // eslint-disable-next-line
            return this.quickSearchTerms.sort()
        },
    },
    methods: {
        getLoggedInStatus: async function () {
            let result = await app.config.globalProperties.$auth
                .getUser()
                .then((response) => {
                    return response
                }) // code to run on success
                .catch((error) => {
                    console.log(error)
                }) // code to run on error

            if (result) {
                this.isLoggedIn = true
            }
            // set loading to false when request finish

            return result
        },
        setQuickSearch: function (term) {
            this.search = term
            this.activeDepartment = ''
        },
        clearSearch: function () {
            this.search = ''
            this.activeDepartment = ''
        },
        setQuickDepartment: function (term) {
            this.search = term
            this.activeDepartment = term
        },
        goToLink: function (link) {
            // window.location.assign(link)
            window.open(link, "_blank");
        },
    },
})
</script>

<style scoped>
@import './styles/Home.css';
</style>
