<template>
    <div>
        <div class="welcome-loading-box" v-if="loading">
            <Loading class="loading"></Loading>
            <div>loading</div>
        </div>
        <div v-if="!loading">
            <div class="new-user-banner">
                <AppButton v-if="newUser" class="quick-button" v-bind:appLink="newUserRedirect" v-bind:appText="'Thank you for visiting myVermont!'" v-bind:appDesc="quickBtnDesc" v-bind:fontawesomePath="quickButtonFa" v-bind:svgPath="svgPath" v-bind:imgPath="quickBtnRedirectImgPath" v-bind:btnCall="quickButtonCall" :helpLink="quickBtnHelpPath" :router-link="routerLink" :agencyDept="quickBtnAgency"></AppButton>
                <div v-if="newUserRedirect !== '' && !stopRedirect">
                    <p v-if="quickBtnRedirectCnt >=0">You will be sent back to <b>{{ quickBtnApp }}</b> in <b>{{ quickBtnRedirectCnt }}</b> seconds.</p>
                    <p v-else>Now redirecting you to <b>{{ quickBtnApp }}</b>. Please click button above if you are not automatically redirected.</p>
                    <p class="stop-pill" v-on:click="stopRedirectTime">Click here to stay on this page.</p>
                </div>
            </div>
            <WelcomeCustomer :admin="isAdmin" :vdh="isVdh" :envLinks="envLinks" :groups="groups"></WelcomeCustomer>
            <!-- -->
        </div>
    </div>
</template>

<script>
import WelcomeCustomer from './WelcomeCustomer'
import { createApp } from 'vue'

const app = createApp({})
import Loading from './features/LoadingWidget'
import AppButton from './features/AppButton'

export default {
    name: 'welcome-user',
    components: {
        WelcomeCustomer,
        Loading,
        AppButton,
    },
    props: ['envLinks'],
    setup() {},
    data() {
        return {
            loading: false,
            isVdh: false,
            isCustomer: false,
            isAdmin: false,
            newUser: false,
            groups:[],
            newUserRedirect: '',
            dllEduNewUser: false,
            quickButtonCall: 'Go now...',
            quickButtonFa: '',
            quickBtnDesc: '',
            quickBtnApp: '',
            quickBtnAgency: '',
            quickBtnHelpPath:'',
            quickBtnRedirectCnt: 10,
            quickBtnRedirectImgPath: '',
            stopRedirect: false,
            timer: 0,
        }
    },
    created() {
        this.makeRequest()
        let fromAppCookie = this.getCookie('targetAppCookie')
        console.log("fromAppCookie",fromAppCookie)
        if (typeof fromAppCookie !== 'undefined' && fromAppCookie !== null && fromAppCookie !== "nocookie") {
            this.newUser = true
            switch (fromAppCookie) {
                case 'dllportal':
                    this.newUserRedirect = this.envLinks.dllLicensing
                    this.quickButtonFa = 'beer'
                    this.quickBtnApp = 'the Department of Liquor and Lottery Licensing Portal'
                    this.quickBtnDesc = 'Your myVermont can be used for the Department of Liquor and Lottery Licensing Portal and the other State of Vermont applications listed below.'
                    this.quickButtonCall = 'Return to DLL Portal Now...'
                    this.timer = setInterval(this.newUserRedirectTimer, 1000)
                    this.quickBtnHelpPath = 'help-dll'
                    this.quickBtnAgency = 'Dept. of Liquor and Lottery'
                    break
                case 'dllstorefront':
                    this.newUserRedirect = this.envLinks.dllEducation
                    this.dllEduNewUser = true
                    this.quickBtnApp = 'the Department of Liquor and Lottery Licensing Storefront'
                    this.quickBtnDesc = 'Your myVermont can be used for the Department of Liquor and Lottery Education Storefront and the other State of Vermont applications listed below.'
                    this.svgPath = 'dllEduLogo'
                    this.quickButtonCall = 'Return to DLL Storefront now...'
                    this.timer = setInterval(this.newUserRedirectTimer, 1000)
                    this.quickBtnHelpPath = 'help-dll'
                    this.quickBtnAgency = 'Dept. of Liquor and Lottery'
                    break
                default:
                    this.quickButtonCall = 'myVT Home'
                    this.quickBtnDesc = 'Your myVermont can be used for a range of State of Vermont applications. Click the button for a complete list of myVermont applications. Apps you have been specifically granted access to may be listed at the bottom of this page.'
                    this.quickBtnRedirectImgPath = 'sovLogo.png'
                    this.quickBtnHelpPath = 'help-home'
                    this.quickBtnAgency = 'my.vermont.gov'
                    this.routerLink = 'home'
                    break
            }
        } else {
            this.newUser = false
        }
    },
    methods: {
        makeRequest() {
            this.loading = true
            app.config.globalProperties.$auth
                .getUser()
                .then((response) => {
                    this.groups = response.Groups
                    if (this.groups.find((a) => a.includes('AHS-VDH-OKTA'))) {
                        this.isVdh = true
                    } else {
                        this.isVdh = false
                    }
                    if (this.groups.find((a) => a.includes('Customer'))) {
                        this.isCustomer = true
                    } else {
                        this.isCustomer = false
                    }
                    if (this.groups.find((a) => (a.includes('Admin') || a.includes('Tier')))) {
                        this.isAdmin = true
                    } else {
                        this.isAdmin = false
                    }
                }) // code to run on success
                .catch((error) => {
                    console.log(error)
                }) // code to run on error
                .finally(() => (this.loading = false)) // set loading to false when request finish
        },
        getCookie(cname) {
            let name = cname + '='
            let decodedCookie = decodeURIComponent(document.cookie)
            let ca = decodedCookie.split(';')
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i]
                while (c.charAt(0) == ' ') {
                    c = c.substring(1)
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length)
                }
            }
            return 'nocookie'
        },
        async newUserRedirectTimer() {
            /* Increment the timer seconds */
            this.quickBtnRedirectCnt--
            if (this.quickBtnRedirectCnt <= 0) {
                window.location.href = this.newUserRedirect
            }
        },
        stopRedirectTime() {
            clearInterval(this.timer)
            this.stopRedirect = true
        },
    },
    computed: {
        getDescWithCurrentSeconds() {
            return this.quickBtnDesc.replace('{{secs}}', this.quickBtnRedirectCnt)
        },
    },
}
</script>

<style scoped>
@import './styles/Welcome.css';
</style>
