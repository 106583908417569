// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faFileUpload, faMoneyBillAlt, faStarOfLife, faChild, faKey, faBusinessTime, faUtensils, faBeer, faSearch, faMinusCircle, faBookReader, faTruck, faTruckMonster, faHandPointLeft, faFileSignature } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faInfoCircle, faFileUpload, faMoneyBillAlt, faStarOfLife, faChild, faKey, faBusinessTime, faUtensils, faBeer, faSearch, faMinusCircle, faBookReader, faTruck, faTruckMonster, faHandPointLeft, faFileSignature)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.config.silent = true
Vue.config.errorHandler = () => null;
Vue.config.warnHandler = () => null;
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
